import React, { Component } from 'react'
import { Button } from 'reactstrap'
import Helmet from 'react-helmet'

import Layout from './../components/Layout'
import IMGAwards from './../assets/images/mhotspot-awards.jpg'

const styles = {
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    width: 350,
  },
}

export class download extends Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>Download mHotspot free</title>
        </Helmet>
        <div>
          <h1 style={{ fontSize: 26 }}>Download latest mHotspot for Windows</h1>
          <br />
          <div style={styles.buttonsContainer}>
            <a href="/mHotspot_installer.exe">
              <Button color="primary" size="lg" style={styles.button}>
                Download for Windows
              </Button>
            </a>
            {/* <br />
            <a
              href="https://play.google.com/store/apps/details?id=com.mhotspot.android"
              target="_new"
            >
              <Button color="primary" size="lg" style={styles.button}>
                Download for Android
              </Button>
            </a> */}
            <br />
            <Button color="secondary" disabled size="lg" style={styles.button}>
              Download for Linux (Coming Soon)
            </Button>
          </div>
        </div>

        <br />
        <br />
        <div>
          <h2 style={{ fontSize: 26 }}>Awards for mHotspot</h2>
          <br />
          <img src={IMGAwards} alt="mhotspot awards" />
        </div>
      </Layout>
    )
  }
}

export default download
